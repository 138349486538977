import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/Page/ContentfulPage'

import SignUp from '../components/FS/SignUp'
import withContentfulBlocks from '../components/withContentfulBlocks'

class RootIndex extends React.Component {
  render () {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    return (
      <ContentfulPage blocks={this.props.blocks}>
        <Helmet title={siteTitle} />
        <SignUp />
      </ContentfulPage>
    )
  }
}

export default withContentfulBlocks(RootIndex)

export const pageQuery = graphql`
  query FSHomeQuery {
    site {
      siteMetadata {
        siteName
      }
    }
    page: contentfulPage(contentful_id: { eq: "42ijkM7eLqzCnhSHGwWOW7" }) {
      ...PageBlocksFragment
    }
  }
`
