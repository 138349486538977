import React from 'react'
import get from 'lodash/get'

export default (WrappedComponent) => {
  return (props) => {
    const blocks = get(props, 'data.page.blocks')
    if (!blocks) {
      throw new Error('Content query did not return any content. Are you referring to an existing id?')
    }
    return <WrappedComponent {...props} blocks={blocks} />
  }
}
