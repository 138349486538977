import React from 'react'
import Page from '../Page'
import HeroCta from '../HeroCta'

const SignUp = () => {
  return (
    <Page.Panel color='brand'>
      <HeroCta url='//www.example.com' buttonText='Request a demo' />
    </Page.Panel>
  )
}

export default SignUp
